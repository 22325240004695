.liberacao-venda{
  text-align: center;
  margin-top: 18px;
  h4{
    margin: 10px;
    font-weight: 500;
  }
  .cronometro{
    ul{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0px;
      li{
        display: inline-block;
        font-size: 18px;
        list-style-type: none;
        padding: 0em 1em 0.2em 1em;
        text-transform: uppercase;
        @media screen and (max-width: 600px) {
          padding: 0em 0.5em 0.2em 0.5em;
        }
        p{
          display: block;
          font-size: 48px;
          line-height: 58px;
          font-weight: bold;
          color: var(--text-primary);
        }
      }
      .colon{
        font-size: 2em;
        padding: 0 0 32px 0;
      }
    }
  }
}

.redirecionando{
  font-size: 18px;
  a{
    color: var(--text-primary);
  }
}